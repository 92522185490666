import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from 'react-google-autocomplete'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import { faGlobe, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Axios from 'axios'
import moment from 'moment'
import Loader from '../Loading/Loader'
import { useNavigate } from 'react-router-dom'
import { SearchToursDetail } from '../../Redux/Actions/actions'

function PackageSearch () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  var endpoint = ApiEndPoint()

  const [loading, setLoading] = useState(false)
  const [umrahPackageId, setUmrahPackageId] = useState('')
  const [allcateogries, setAllCateogries] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [tourdestination, setTourdestination] = useState(null)
  const [selectedTourDate, setSelectedTourDate] = useState(null)
  useEffect(() => {
    GetPackages()
  }, [])

  const GetPackages = async () => {
    var token = Hotelapitoken()
    const fullURL = window.location.href
    var data = {
      token: token,
      currentURL: fullURL,
      limit: 6
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/get_website_index_data',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setUmrahPackageId(response.data.all_cateogries[0].id)
      setAllCateogries(response.data.all_cateogries)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleSelectUmrahPackage = event => {
    setUmrahPackageId(event.target.value)
  }
  const handleTourDateChange = date => {
    setSelectedTourDate(date)
  }
  const ToursSearchbtn = async () => {
    if (umrahPackageId !== '' && selectedTourDate !== null) {
      var token = Hotelapitoken()
      var data = {
        // token: token,
        category: umrahPackageId,
        start_date: moment(selectedTourDate).format('YYYY-MM-DD'),
        search_for: 'tour'
      }
      navigate('/our-packages', { state: data })
    } else {
      toast.error('Please Select Data Again!', {
        position: 'top-right', // You can customize the position
        autoClose: 3000 // Auto close the notification after 3 seconds
      })
    }
  }
  return (
    <>
      <div
        role='tabpanel'
        class='tab-pane custom-search-home  active '
        id='bravo_hotel'
      >
        {loading && <Loader />}
        <ToastContainer />

        <div
                class="tab-pane "
                id="hotels"
                role="tabpanel"
                aria-labelledby="hotels-tab"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="tour_search_form">
                     
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="flight_Search_boxed">
                              <p>Packages</p>
                              <select
                        value={umrahPackageId}
                        onChange={handleSelectUmrahPackage}
                        className="form-select "
                        style={{background:"#8b3eea00", border:"none"}}
                        placeholder='Search Packages'
                        aria-label="Default select example"
                      >
                        {allcateogries.map((item, index) => (
                          <option
                            key={item.id}
                            selected={index === 0}
                            value={item.id}
                          >
                            {item.title}
                          </option>
                        ))}
                      </select>
                             
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="flight_Search_boxed">
                              <p>Return</p>
                              <DatePicker
                        selected={selectedTourDate}
                        minDate={new Date()}
                        onChange={handleTourDateChange}
                        placeholderText="Select Date"
                        dateFormat="dd/MM/yyyy" // Customize date format as needed
                        className="form-control text-start select-stylings"
                        style={{ width: "100%", background:"transparent" }}
                      />
                            
                            </div>
                          </div>
                        
                         
                          <div class="top_form_search_button">
                            <button class="btn btn_theme btn_md"   type="submit"
                  onClick={(event) => ToursSearchbtn(event)}>Search</button>
                          </div>
                        </div>
                     
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default PackageSearch
