import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Hotelapitoken, ApiEndPoint } from '../../Components/GlobalData/GlobalData'
import { Modal, DateRangePicker, Popover, Whisper } from 'rsuite'

import dubai from '../../Images/Hotels/uae.jpeg'
import london from '../../Images/Hotels/london.jpg'
import newyork from '../../Images/Hotels/newyork.jpg'
import istambol from '../../Images/Hotels/istanbol.jpg'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import { fetchHotels, fetchHotelsSearh } from '../../Redux/Actions/actions'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Components/Loading/Loader'
const { beforeToday } = DateRangePicker
function TopHotels () {
  var token = Hotelapitoken()
  const dispatch = useDispatch()
  var apiendpoint = ApiEndPoint()
  const navigate = useNavigate()
  const [t, i18n] = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [location, setLocation] = useState('')
  const [selectDate, setSelectDate] = useState(null)
  const language = i18n.language
  const [packages, setPackages] = useState([])
  const [userLocation, setUserLocation] = useState([])
  const [isWhisperOpen, setIsWhisperOpen] = useState(false)
  const [childAgesError, setChildAgesError] = useState({})
  const [validation, setValidation] = useState('')
  const [loading, setLoading] = useState(false)
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  })
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: []
    }
  ])
  const handleOpen = name => {
    setLocation(name)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  useEffect(() => {
    GetPackages()
    getUserLocation()
  }, [])
  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0)
    const children = rooms.reduce((acc, item) => acc + item.children, 0)
    const roomss = rooms.length
    setPersonData(prevPersonData => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss
    }))
  }, [rooms])

  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        setUserLocation(data)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }

  const GetPackages = async () => {
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      var packagesCount
      if (response.data.latest_packages.length !== 0) {
        var umrah = response.data.latest_packages[0].length
        var alaqsa = response.data.latest_packages[1].length
        var turkey = response.data.latest_packages[2].length
        packagesCount = {
          umrahs: umrah,
          alaqsas: alaqsa,
          turkeys: turkey
        }
      }
      setPackages(packagesCount)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const handledatechange = date => {
    setSelectDate(date)
  }
  const handleCloseWhisper = () => {
    let ageStatus = false
    let messages = {} // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i]

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[`room-${i}-child-${j}`] = `Please select child age.`
            ageStatus = true
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages) // Store messages for each specific child missing an age
      return
    }

    setChildAgesError({})
    setIsWhisperOpen(false)
  }
  const handleAdultIncrement = roomIndex => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1
      setRooms(updatedRooms)
    }
  }
  const handleAdultDecrement = roomIndex => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }
  const handleChildrenIncrement = roomIndex => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1
      setRooms(updatedRooms)
    }
  }
  const handleChildrenDecrement = roomIndex => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }
  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms]

    if (value === '') {
      // Remove the age for this child if value is empty
      updatedRooms[roomIndex].childrenAges = updatedRooms[
        roomIndex
      ].childrenAges.filter((_, index) => index !== childIndex)
    } else {
      // Update or add the age if value is not empty
      updatedRooms[roomIndex].childrenAges[childIndex] = value
    }

    setRooms(updatedRooms)
  }
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }])
    }
  }
  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1))
    }
  }
  const handleDeleteRoomByIndex = roomIndex => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex)
    setRooms(updatedRooms)
  }
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year'
  ]
  const speaker = (
    <Popover style={{ width: '21em' }} title='Select Detail'>
      {rooms.map((room, roomIndex) => (
        <div key={roomIndex} className='availability_search'>
          <div
            className='hotel-detail-border d-flex text-center fw-bold mb-2'
            style={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <p className='fw-bold m-1'>Room {roomIndex + 1}</p>
            {roomIndex > 0 && ( // Check if roomIndex is greater than 0
              <button
                className='delete-room-button m-1'
                onClick={() => handleDeleteRoomByIndex(roomIndex)}
              >
                Delete
              </button>
            )}
          </div>
          <div>
            <div className='select-child'>
              <div class='f4878764f1'>
                <label class='a984a491d9 fw-bold' for='group_adults'>
                  Adults
                </label>
              </div>
              <div class='d-flex fff'>
                <button
                  className='adult-modal-btn'
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultDecrement(roomIndex)}
                >
                  <i class='fas '>
                    <FontAwesomeIcon size='sm' icon={faMinus} />
                  </i>
                </button>
                <span className='d723d73d5f fw-bold' id='input'>
                  {room.adults}
                </span>
                <button
                  className='adult-modal-btn'
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultIncrement(roomIndex)}
                  id='increment'
                >
                  <i class='fas'>
                    <FontAwesomeIcon size='sm' icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className='select-child'>
              <div class='f4878764f1'>
                <label class='a984a491d9 fw-bold' for='group_adults'>
                  Children
                </label>
              </div>
              <div class='d-flex fff'>
                <button
                  className='adult-modal-btn'
                  onClick={() => handleChildrenDecrement(roomIndex)}
                  id='decrement'
                >
                  <i class='fas '>
                    <FontAwesomeIcon size='sm' icon={faMinus} />
                  </i>
                </button>
                <span className='d723d73d5f fw-bold' id='input'>
                  {room.children}
                </span>
                <button
                  className='adult-modal-btn'
                  onClick={() => handleChildrenIncrement(roomIndex)}
                  id='increment'
                >
                  <i class='fas'>
                    <FontAwesomeIcon size='sm' icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className='select-child' style={{ justifyContent: 'left' }}>
              {room.children > 0 && (
                <div
                  style={{ flexWrap: 'wrap' }}
                  className='d-flex justify-content-between'
                >
                  {Array.from({ length: room.children }, (_, childIndex) => (
                    <div key={childIndex} className='m-1 '>
                      <label>Child {childIndex + 1} (Age)</label>
                      <select
                        class='form-select child-age-select'
                        name='child1'
                        value={room.childrenAges[childIndex]}
                        onChange={e =>
                          handleChildAgeChange(
                            roomIndex,
                            childIndex,
                            e.target.value
                          )
                        }
                        aria-label='Default select example'
                      >
                        <option value='' selected>
                          Age needed
                        </option>
                        {childAgearray.map((item, index) => (
                          <option key={index} value={index + 1}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {childAgesError[
                        `room-${roomIndex}-child-${childIndex}`
                      ] && (
                        <p className='text-danger'>
                          {
                            childAgesError[
                              `room-${roomIndex}-child-${childIndex}`
                            ]
                          }
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div>
        <div className='select-child availability_search mt-4'>
          <div class='f4878764f1 pe-4'>
            <label class='a984a491d9 fw-bold' for='group_adults'>
              Room
            </label>
          </div>
          <div class='d-flex fff'>
            <button
              className='adult-modal-btn'
              onClick={removeLastRoom}
              id='decrement'
            >
              <i class='fas '>
                <FontAwesomeIcon size='sm' icon={faMinus} />
              </i>
            </button>
            <span className='d723d73d5f fw-bold' id='input'>
              {rooms.length}
            </span>
            <button
              className='adult-modal-btn'
              onClick={addRoom}
              id='increment'
            >
              <i class='fas'>
                <FontAwesomeIcon size='sm' icon={faPlus} />
              </i>
            </button>
          </div>
          <button
            onClick={handleCloseWhisper}
            className='theme-btn-1  ps-3 pe-3 py-1'
          >
            Apply
          </button>
        </div>
      </div>
    </Popover>
  )
  const Searchhotels = async name => {
    if (selectDate === null) {
      setValidation('Please select checkin and checkout dates.')
      return
    }
    var destination_name = ''
    var country = ''
    var lat = ''
    var lon = ''
    var pin = ''
    if (location === 'makkah') {
      destination_name = 'Makkah'
      country = 'Saudi Arabia'
      lat = 21.4240968
      lon = 39.81733639999999
      pin = 'SA'
    } else if (location === 'madinah') {
      destination_name = 'Madinah'
      country = 'Saudi Arabia'
      lat = 24.4672132
      lon = 39.6024496
      pin = 'SA'
    } else if (location === 'jeddah') {
      destination_name = 'Jeddah'
      country = 'Saudi Arabia'
      lat = 21.5291545
      lon = 39.1610863
      pin = 'SA'
    } else if (location === 'dubai') {
      destination_name = 'Dubai'
      country = 'United Arab Emirates'
      lat = 25.2048493
      lon = 55.2707828
      pin = 'AE'
    } else if (location === 'london') {
      destination_name = 'London'
      country = 'United Kingdom'
      lat = 51.5072178
      lon = -0.1275862
      pin = 'GB'
    } else if (location === 'newyork') {
      destination_name = 'New York'
      country = 'United States'
      lat = 40.7127753
      lon = -74.0059728
      pin = 'US'
    } else if (location === 'paris') {
      destination_name = 'Paris'
      country = 'France'
      lat = 48.8575475
      lon = 2.3513765
      pin = 'FR'
    } else if (location === 'istanbol') {
      destination_name = 'İstanbul'
      country = 'Türkiye'
      lat = 41.0082376
      lon = 28.9783589
      pin = 'TR'
    }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      destination_name: destination_name,
      country: country,
      lat: lat,
      long: lon,
      pin: pin,
      cityd: destination_name,
      country_code: pin,
      check_in: moment(selectDate[0]).format('YYYY-MM-DD'),
      check_out: moment(selectDate[1]).format('YYYY-MM-DD'),
      slc_nationality: userLocation.country.name,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    const queryString = new URLSearchParams(FormData).toString()
    navigate(`/hotels?${queryString}`)
  }
  const modalocationchange = event => {
    setLocation(event.target.value)
  }
  return (
    <>
      {loading && <Loading />}
      <ToastContainer />
      <Modal
        backdrop='static'
        className='home-city-modal'
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Search Criteria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-2'>
            <label>Location</label>
            <select
              value={location}
              onChange={modalocationchange}
              class='form-select'
              aria-label='Default select mt-1 example'
            >
              <option value='dubai'>Dubai</option>
              <option value='london'>London</option>
              <option value='newyork'>New York</option>
              <option value='istanbol'>Istanbul</option>
            </select>
          </div>
          <div
            style={{ flexWrap: 'wrap' }}
            className='home-adult-modal justify-content-between p-2 '
          >
            <div className=''>
              <label>Select Dates</label>
              <div>
                <DateRangePicker
                  className='w-100'
                  disabledDate={beforeToday()}
                  format='dd-MM-yyyy'
                  value={selectDate}
                  onChange={handledatechange}
                />
              </div>
            </div>
            <div className=''>
              <label>Select Rooms</label>
              <div>
                <div className='field-icon-wrap'>
                  <Whisper
                    open={isWhisperOpen}
                    placement='bottom'
                    speaker={speaker}
                    preventClose
                  >
                    <button
                      onClick={() => setIsWhisperOpen(true)}
                      className='btn text-left  btn-primary btn-block select-styling button-2 search-btn1'
                      style={{
                        color: 'black',
                        background: 'none'
                      }}
                    >
                      {personData.adult}{' '}
                      {Number(personData.adult) > 1 ? 'Adults' : 'Adult'} .{' '}
                      {personData.children}{' '}
                      {Number(personData.children) > 1
                        ? 'Childrens'
                        : 'Children'}{' '}
                      . {personData.room}{' '}
                      {Number(personData.room) > 1 ? 'Rooms' : 'Room'}
                    </button>
                  </Whisper>
                </div>
              </div>
            </div>
          </div>
          {validation && <p className='text-danger'>{validation}</p>}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={Searchhotels} className='btn me-2 btn-success'>
            Search
          </button>
          <button onClick={handleClose} className='btn btn-secondary'>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      
      <section class='gray-simple home-city mt-5'>
        <div class='container'>
          <div class='row align-items-center justify-content-center'>
            <div class='col-xl-8 col-lg-9 col-md-11 col-sm-12'>
              <div class='secHeading-wrap text-center mb-5'>
              <h2 className="text-center mb-5" style={{color:"#EA0876"}}>See the world in a Variety of Ways</h2>

                {/* <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p> */}
              </div>
            </div>
          </div>

          <div class='row align-items-center justify-content-center g-xl-4 g-lg-4 g-3'>         
            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 cursor-pointer rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs' onClick={() => handleOpen('dubai')}>
                    <div class='imagination_boxed'>
                      <a >
                        <img src={dubai} class='img-fluid' alt='' />
                      </a>
                      <h3>
                    <a className="text-light" >
                    Dubai
                    </a>
                  </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card cursor-pointer border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs' onClick={() => handleOpen('london')}>
                    <div class='imagination_boxed'>
                      <a >
                        <img src={london} class='img-fluid' alt='' />
                      </a>
                      <h3>
                    <a className="text-light" >
                    London
                    </a>
                  </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card cursor-pointer border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs' onClick={() => handleOpen('newyork')}>
                    <div class='imagination_boxed'>
                      <a >
                        <img src={newyork} class='img-fluid' alt='' />
                      </a>
                      <h3>
                    <a className="text-light" >
                    New York
                    </a>
                  </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card cursor-pointer border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs' onClick={() => handleOpen('istanbol')}>
                    <div class='imagination_boxed'>
                      <a >
                        <img src={istambol} class='img-fluid' alt='' />
                      </a>
                      <h3>
                    <a className="text-light" >
                    Istanbul
                    </a>
                  </h3>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TopHotels
