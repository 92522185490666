import React, { useEffect, useState } from "react";
import img1 from "../../Images/Carousal/1.jpg";
import imagination1 from "../../img/imagination/imagination1.png";
import imagination2 from "../../img/imagination/imagination2.png";
import imagination3 from "../../img/imagination/imagination3.png";
import imgg1 from "../../Images/Home/1.jpg";
import imgg2 from "../../Images/Home/2.jpg";
import imgg3 from "../../Images/Home/3.jpg";
import imgg4 from "../../Images/Home/4.jpg";
import imgg5 from "../../Images/Home/5.jpg";
import imgg6 from "../../Images/Home/6.jpg";
import off1 from "../../Images/Home/7.jpg";
import off2 from "../../Images/Home/8.jpg";
import off3 from "../../Images/Home/9.jpg";
import offer1 from "../../img/offer/offer1.png";
import offer2 from "../../img/offer/offer2.png";
import offer3 from "../../img/offer/offer3.png";
// import '../../Components/Carousal/style.css'
// import bg from "../../img/banner/bg.png";
import img2 from "../../Images/Carousal/2.jpg";
import img3 from "../../Images/Carousal/3.jpg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";
import SearchBar from "../../Components/SearchBar/Searchbar";
import Packages from "../../Components/Packages/Packages";
import agentpic from "../../Images/Home/agentpic.png";
import adimage from "../../Images/Home/qurbani_ad.jpeg";
import Hotels from "../../Components/Hotel/Hotels";
import MorePackages from "../../Components/MorePackages/MorePackages";
import Layout from "../../Components/Layout/Layout";
import Loader from "../../Components/Loading/Loader";
import Helmet from "react-helmet";
import wow from "wowjs";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

import home1 from "../../Images/New/image_home_mix_1.jpg";
import home2 from "../../Images/New/image_home_mix_2.jpg";
import home3 from "../../Images/New/image_home_mix_3.jpg";
import IndexComponents from "./IndexComponents";
import location1 from "../../Images/New/location-1.jpg";
import location2 from "../../Images/New/location-2.jpg";
import location3 from "../../Images/New/location-3.jpg";
import location4 from "../../Images/New/location-4.jpg";
import location5 from "../../Images/New/location-5.jpg";
import avatar1 from "../../Images/New/avatar.jpg";
import avatar2 from "../../Images/New/avatar-2.jpg";
import avatar3 from "../../Images/New/avatar-3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import img4 from "../../Images/Carousal/5.jpg";
import MoreOffer from "./TopExcursions";
import BestCollection from "./ExploreSaudi";

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [bottomAdd, setShowbottomAdd] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [valueFromChild, setValueFromChild] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    new wow.WOW().init();

    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const hideadd = () => {
    setShowbottomAdd(false);
  };
  const gotoHajjPage = () => {
    navigate("/hajj");
  };
  const handleValueChange = (newValue) => {
    setValueFromChild(newValue);
  };
  return (
    <>
      {isLoading && <Loader />}
      <Layout>
        <section id="home_one_banner">
          {/* -----------------banner area------------------- */}
          <section>
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <div class="banner_one_text">
                    <h1>Explore the world together</h1>
                    <h3>Find awesome flights, hotel, tour, car and packages</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <SearchBar onValueChange={handleValueChange} />

        {/* -------------imaginations--------------- */}

        <section id="go_beyond_area" class="section_padding_top ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="heading_left_area">
                  <h2>
                    Go beyond your <span>imagination</span>
                  </h2>
                  <h5>Discover your ideal experience with us</h5>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={imgg2} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light">
                      7% Discount for all <span>Makkah Hotels</span>
                    </a>
                  </h3>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={imgg1} alt="img" />
                  </a>
                  <h3>
                    <a  className="text-light">
                      Travel around<span>the world</span>
                    </a>
                  </h3>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="imagination_boxed">
                  <a>
                    <img src={imgg3} alt="img" />
                  </a>
                  <h3>
                    <a className="text-light">
                      Activities<span>top deals</span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BestCollection />
        <MoreOffer />
      </Layout>
    </>
  );
}

export default Home;
