import React, { useEffect, useState } from 'react'

function InvoiceAdultDetail ({ invoiceDetail }) {

  return (
    <>
      <div class='cs-round_border mt-4'>
        <div class='cs-table_responsive'>
          <table class='cs-border_less' style={{width:'100%'}}>
            <tbody>
              <tr className='p-2'>
                <th colspan='2' class='cs-primary_color p-2'>Rooms</th>
                <th className='p-2' colspan='2'>No of Pax</th>
                <th className='p-2'>Price</th>
              </tr>
              {invoiceDetail.double_rooms !== null && (
                <tr className='p-2'>
                  <td colspan='2' class='cs-primary_color p-2 cs-f14'>
                    <i className='fa-solid fa-bed'></i> Double Rooms X{' '}
                    {invoiceDetail.double_rooms}
                  </td>
                  <td colspan='2' class='cs-f14 p-2'>
                    {invoiceDetail?.double_adults &&
                      invoiceDetail?.double_adults !== null &&
                      Number(invoiceDetail?.double_adults) > 0 && (
                        <span> Adults {invoiceDetail?.double_adults},</span>
                      )}
                    {invoiceDetail?.double_childs &&
                      invoiceDetail?.double_childs !== null &&
                      Number(invoiceDetail?.double_childs) >
                        0 && (
                        <span>
                          {' '}
                          Childs {invoiceDetail?.double_childs},
                        </span>
                      )}
                    {invoiceDetail?.double_infant &&
                      invoiceDetail?.double_infant !== null &&
                      Number(invoiceDetail?.double_infant) >
                        0 && (
                        <span>
                          {' '}
                          Infants{' '}
                          {invoiceDetail?.double_infant}
                        </span>
                      )}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {Number(invoiceDetail?.double_adult_total) +
                      Number(invoiceDetail?.double_childs_total) +
                      Number(invoiceDetail?.double_infant_total)}
                  </td>
                </tr>
              )}
              {invoiceDetail.triple_rooms !== null && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color p-2 cs-f14'>
                    <i className='fa-solid fa-bed'></i> Triple Rooms X{' '}
                    {invoiceDetail.triple_rooms}
                  </td>
                  <td colspan='2' class='cs-f14 p-2'>
                    {invoiceDetail?.triple_adults &&
                      invoiceDetail?.triple_adults !== null &&
                      Number(invoiceDetail?.triple_adults) > 0 && (
                        <span> Adults {invoiceDetail?.triple_adults},</span>
                      )}
                    {invoiceDetail?.triple_childs &&
                      invoiceDetail?.triple_childs &&
                      Number(invoiceDetail?.triple_childs) >
                        0 && (
                        <span>
                          {' '}
                          Childs {invoiceDetail?.triple_childs},
                        </span>
                      )}
                    {invoiceDetail?.triple_infant &&
                      invoiceDetail?.triple_infant !== null &&
                      Number(invoiceDetail?.triple_infant) >
                        0 && (
                        <span>
                          {' '}
                          Infants{' '}
                          {invoiceDetail?.triple_infant}
                        </span>
                      )}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {Number(invoiceDetail.triple_adult_total) +
                      Number(invoiceDetail.triple_childs_total) +
                      Number(invoiceDetail.triple_infant_total)}
                  </td>
                </tr>
              )}
              {invoiceDetail.quad_rooms !== null && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color p-2 cs-f14'>
                    <i className='fa-solid fa-bed'></i> Quad Rooms X{' '}
                    {invoiceDetail.quad_rooms}
                  </td>
                  <td colspan='2' class='cs-f14 p-2'>
                    {invoiceDetail?.quad_adults &&
                      invoiceDetail?.quad_adults !== null &&
                      Number(invoiceDetail?.quad_adults) > 0 && (
                        <span> Adults {invoiceDetail?.quad_adults},</span>
                      )}
                    {invoiceDetail?.quad_childs &&
                      invoiceDetail?.quad_childs !== null &&
                      Number(invoiceDetail?.quad_childs) > 0 && (
                        <span>
                          {' '}
                          Childs {invoiceDetail?.quad_childs},
                        </span>
                      )}
                    {invoiceDetail?.quad_infant &&
                      invoiceDetail?.quad_infant !== null &&
                      Number(invoiceDetail?.quad_infant) >
                        0 && (
                        <span>
                          {' '}
                          Infants {invoiceDetail?.quad_infant}
                        </span>
                      )}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {Number(invoiceDetail.quad_adult_total) +
                      Number(invoiceDetail.quad_child_total) +
                      Number(invoiceDetail.quad_infant_total)}
                  </td>
                </tr>
              )}
              <tr className='invoice-hr p-2'><td colspan="12" className='p-2'><div className='package_inv_hr' /></td></tr>
              <tr className=''>
                <th colspan='2' class='cs-primary_color p-2'>Rooms</th>
                <th className='p-2'>No of Pax</th>
                <th className='p-2'>Price Per Person</th>
                <th className='p-2'>Discount</th>
                <th className='p-2'>Total</th>
                </tr>
                {invoiceDetail.double_adults!== null && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color p-2 cs-f14'>
                    <i className='fa-solid fa-bed'></i> Adults in Double Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold p-2'>
                    {invoiceDetail?.double_adults} Adults
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.sharing2}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.double_adult_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.double_adult_total}
                  </td>
                </tr>
              )}
               {invoiceDetail.double_childs !== null && Number(invoiceDetail.double_childs) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color p-2 cs-f14'>
                    Childs in Double Room{' '}
                   
                  </td>
                  <td class='cs-f14 p-2 fw-bold'>
                    {invoiceDetail?.double_childs} Childs
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.double_child_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.double_child_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.double_childs_total}
                  </td>
                </tr>
              )}
              {invoiceDetail.double_infant !== null   && Number(invoiceDetail.double_infant) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color p-2 cs-f14'>
                    Infants in Double Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold p-2'>
                    {invoiceDetail?.double_infant } Infants
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.double_infant_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.double_infant_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.double_infant_total}
                  </td>
                </tr>
              )}
                {invoiceDetail.triple_adults!== null && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Adults in Triple Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.triple_adults} Adults
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.sharing3}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.triple_adult_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.triple_adult_total}
                  </td>
                </tr>
              )}
               {invoiceDetail.triple_childs!== null && Number(invoiceDetail.triple_childs) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                   Childs in Triple Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.triple_childs} Childs
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.triple_child_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.triple_child_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.triple_childs_total}
                  </td>
                </tr>
              )}
              {invoiceDetail.triple_infant !== null   && Number(invoiceDetail.triple_infant) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                     Infants in Triple Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.triple_infant } Infants
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.triple_infant_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.triple_infant_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.triple_infant_total}
                  </td>
                </tr>
              )}
                {invoiceDetail.quad_adults!== null && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Adults in Quad Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail.quad_adults} Adults
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail.sharing4}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail.quad_adult_disc}
                  </td>
                  <td className='p-2'>
                  {invoiceDetail?.currency} {invoiceDetail.quad_adult_total}
                  </td>
                </tr>
              )}
                {invoiceDetail.quad_childs !== null && Number(invoiceDetail.quad_childs) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Childs in Quad Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.quad_childs} Childs
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.quad_child_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.quad_child_disc_total}
                  </td>
                  <td className='p-2'>
                  {invoiceDetail?.currency} {invoiceDetail?.quad_child_total}
                  </td>
                </tr>
              )}
              {invoiceDetail.quad_infant !== null   && Number(invoiceDetail.quad_infant) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Infants in Quad Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.quad_infant } Infants
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.quad_infant_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.quad_infant_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.quad_infant_total}
                  </td>
                </tr>
              )}
              {invoiceDetail?.without_acc_adults !== null && Number(invoiceDetail.without_acc_adults) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-male'></i> Adults Without bed{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.without_acc_adults} Adults
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.without_acc_adult_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.without_acc_adult_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.without_acc_adult_total}
                  </td>
                </tr>
              )}
              {invoiceDetail?.children !==null && Number(invoiceDetail?.children) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                     Childs Without bed{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.children} Childs
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.child_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.without_acc_child_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.without_acc_child_total}
                  </td>
                </tr>
              )}
              {invoiceDetail?.infants !==null   && Number(invoiceDetail?.infants  ) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Infants Without bed{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {invoiceDetail?.infants} Infants
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.infant_price}
                  </td>
                  <td className='p-2'>
                   {invoiceDetail?.currency} {invoiceDetail?.without_acc_infant_disc}
                  </td>
                  <td className='p-2'>
                    {invoiceDetail.currency}{' '}
                    {invoiceDetail?.without_acc_infant_total}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default InvoiceAdultDetail
