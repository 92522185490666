import React,{useState} from "react";
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import emaill from "../../img/common/email.png";
import Atol from "../../img/common/Atol.png"
import { ToastContainer,toast } from "react-toastify";
import { ApiEndPoint,Hotelapitoken } from "../GlobalData/GlobalData";
import { Loader } from "rsuite";
import axios from "axios";
function Footer () {
  const endpoint=ApiEndPoint();
  const token=Hotelapitoken();
  const [email,setemail]=useState('');
  const [loading,setLoading]=useState(false)
 const submitemail=async()=>{
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if(email===''){
    toast.error('Please enter your email address');
    return;
  }
  if(emailRegex.test(email)){
    var data={
      token:token,
      email:email
    }
    setLoading(true);
   try{
    const response=await axios.post(endpoint+'/api/add_SubscriptionEmail',data)
    setLoading(false);
    if(response.data.status==='success'){
      toast.success(response.data.message);
      setemail('')
    }
  }catch(error){
    setLoading(false);
    console.log(error);
   }
  }else{
      toast.error('Please enter a valid email.');
  }
 }
  return (
    <>
    <ToastContainer/>
   {/* -----------------subscribe---------------- */}

   <section id="cta_area" className="mt-4">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-7">
                <div class="cta_left">
                  <div class="cta_icon">
                    <img src={emaill} alt="icon" />
                  </div>
                  <div class="cta_content">
                    <h4>Get the latest news and offers</h4>
                    <h2>Subscribe to our newsletter</h2>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="cat_form">
                  <div id="cta_form_wrappper">
                    <div class="input-group">
                      <input
                        type="email"
                        value={email}
                        onChange={(e)=>setemail(e.target.value)}
                        class="form-control"
                        placeholder="Enter your mail address"
                      />
                      <button onClick={submitemail}  class="btn btn_theme btn_md" type="button">
                        {loading? (
                          <Loader size="sm" className="mx-4"/>
                        ):(
                          'Subscribe'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    <footer id="footer_area">
        <div class="container">
            <div class="row">

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5 className="fw-bold">Contacts</h5>
                    </div>
                    <div class="footer_first_area">
                        <div class="footer_inquery_area">
                            <h5>Call 24/7 for any help</h5>
                            <h3 > <a href="tel://01254675656" style={{color:"#EA0876", textDecoration:"none"}}>01254 675656</a></h3>
                        </div>
                        <div class="footer_inquery_area">
                            <h5>Mail to our support team</h5>
                            <h3> <a href="mailto:hashimtravel@hotmail.co.uk" style={{color:"#EA0876"}}>hashimtravel@hotmail.co.uk</a></h3>
                        </div>
                        <div class="footer_inquery_area d-flex">
                          <div>
                            <h5>Follow us on</h5>
                            <ul class="soical_icon_footer">
                                <li><a href="/" style={{color:"#EA0876"}}><i class="fab fa-facebook"></i></a></li>
                                <li><a href="/"style={{color:"#EA0876"}}><i class="fab fa-twitter-square"></i></a></li>
                                <li><a href="/" style={{color:"#EA0876"}}><i class="fab fa-instagram"></i></a></li>
                                <li><a href="/" style={{color:"#EA0876"}}><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                          </div>
                          <div className="ms-4">
                            <figure>
                              <img src={Atol} alt="Atol-logo" style={{width:"28%"}}  />
                            </figure>

                          </div>

                        </div>
                    </div>
                </div>

                <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5 className="fw-bold">Company</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/about-us">About Us</NavLink></li>
                            <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                            
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5 className="fw-bold">About Hashim Travel</h5>
                    </div>
                    <div class="footer_link_area">
                    <p>
                Hashim Travel Limited is the leading tour operator in the
                United Kingdom specializing in Hajj and Umrah services. Take
                advantage of our exceptional Hajj and Umrah packages,
                thoughtfully designed to meet the unique needs of pilgrims
                embarking on their significant sacred journey to Saudi Arabia.
              </p>
                    </div>
                </div>
               <div className="row mt-2">
                <div className="col-md-12">
                  <p className="">Some of the flights and flight-inclusive holidays on this website are financially protected by the ATOL scheme. But ATOL protection does not apply to all holiday and travel services listed on this website. This website will provide you with information on the protection that applies in the case of each holiday and travel service offered before you make your booking. If you do not receive an ATOL Certificate then the booking will not be ATOL protected. If you do receive an ATOL Certificate but all the parts of your trip are not listed on it, those parts will not be ATOL protected. Please see our booking conditions for information, or for more information about financial protection and the ATOL Certificate go to: <a href=" https://www.caa.co.uk" >https://www.caa.co.uk</a> </p>
                </div>
               </div>
              
            </div>
        </div>
    </footer>



    <div class="copyright_area">
        <div class="container">
            <div class="row align-items-center">
                <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="copyright_left">
                        <p>© {moment().format('YYYY')} Hashim Travel Limited All Rights Reserved.</p>
                    </div>
                </div>


                <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                <ul id="additional_links">
						<li><NavLink to="/terms_and_conditions">Terms and conditions</NavLink></li>
						<li><NavLink to="/privacy_policy">Privacy</NavLink></li>
            <li><NavLink to="/complaint_policy">Complaint Policy</NavLink></li>
					
					</ul> 
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer
