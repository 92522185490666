import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cimg from '../../img/banner/common-banner.png'
import {
  faStar,
  faSearch,
  faArrowRight,
  faAngleDown,
  faBowlFood,
  faFilter,
  faDollar,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons'
import bgimage from '../../Images/Hotels/bg.jpg'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite'
import HotelCard from '../../Components/HotelCard/Hotelcard'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import Layout from '../../Components/Layout/Layout'
import moment from 'moment'

// New Imports
import Axios from 'axios'
import Loading from '../../Components/Loading/Loader'
import { fetchHotelsSearh, fetchHotels } from '../../Redux/Actions/actions'
import {
  Hotelapitoken,
  ApiEndPoint,
  CurrencyConverter
} from '../../Components/GlobalData/GlobalData'
function Hotels () {
  const token = Hotelapitoken()
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  const [loading, setLoading] = useState(true)
  const [searchHotelList, setSearchHotelList] = useState([])
  const [hotelid, setHotelid] = useState('')
  const [mealRating, setMealRating] = useState({
    meal1: '',
    meal2: '',
    meal3: '',
    meal4: '',
    type: 'meal'
  })
  const [hotelsSearchData, sethotelsSearchData] = useState({})
  const [hotelNameFilter, setHotelNameFilter] = useState(null)
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)
  const [ShowMealTYpeFilter, setShowMealTYpeFilter] = useState(false)
  const [rangeValue, setRangeValue] = useState([])
  const [ShowRatingFilter, setShowRatingFilter] = useState(false)
  const [ShowPriceFilter, setShowPriceFilter] = useState(false)
  const [orderFilter, setOrderFilter] = useState('1')
  const [ShowFacilityFilter, setShowFacilityFilter] = useState(false)
  const [selectedFacilities, setSelectedFacilities] = useState({
    type: 'facility'
  })
  const [starRating, setStarRating] = useState({
    rating5: '0',
    rating4: '0',
    rating3: '0',
    rating2: '0',
    rating1: '0',
    type: 'Stars'
  })

  // Search Hotels

  useEffect(() => {
    Searchhotels()
  }, [])
  const Searchhotels = async () => {
    setLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const parsedData = {}

    for (const [key, value] of urlParams.entries()) {
      if (key === "Adults" || key === "children" || key === "rooms_counter") {
        parsedData[key] = value.split(",").map(Number);
      } else if (key === "lat" || key === "long") {
        parsedData[key] = parseFloat(value);
      } else if (key === "adult" || key === "child" || key === "room") {
        parsedData[key] = parseInt(value, 10);
      } else if (key.startsWith("child_ages")) {
        // Check for child ages
        const roomIndex = parseInt(key.replace("child_ages", ""), 10) - 1; // Get the room index
        parsedData[key] = value ? value.split(",").map(Number) : []; // Split and convert ages to numbers or set to empty array
      } else {
        parsedData[key] = value;
      }
    }
    parsedData.token = token
    parsedData.currency_slc = 'AFN'
    parsedData.currency_slc_iso = 'AF'
    console.log(parsedData)
    sethotelsSearchData(parsedData)
    try {
      dispatch(fetchHotelsSearh(parsedData))
      const response = await Axios.post(
        endpoint + '/api/search/hotels/new_Live',
        parsedData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (
        response.data.status === 'success' &&
        response.data.hotels_list.length !== 0
      ) {
        setSearchHotelList(response.data.hotels_list)
        var minamounts = response.data.hotels_list.map(hotel => {
          let minPrice = Number(hotel.min_price)

          // Admin markup
          if (Number(hotel.admin_markup) !== 0) {
            if (hotel.admin_markup_type === 'Percentage') {
              minPrice +=
                (Number(hotel.min_price) * Number(hotel.admin_markup)) / 100
            } else {
              minPrice += Number(hotel.admin_markup)
            }
          }
          // Customer markup
          if (Number(hotel.customer_markup) !== 0) {
            if (hotel.customer_markup_type === 'Percentage') {
              minPrice +=
                (Number(hotel.min_price) * Number(hotel.customer_markup)) / 100
            } else {
              minPrice += Number(hotel.admin_markup)
            }
          }
          if (Number(minPrice) % 1 !== 0) {
            return Number(minPrice).toFixed(2)
          }
          return minPrice
        })
        var minValue = Math.min(...minamounts)
        var maxValue = Math.max(...minamounts)
        setMinValue(minValue)
        setMaxValue(maxValue)
        setRangeValue([minValue, maxValue])
        const currencies = []
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny)
          }
        })
        const currencyResponse = await AllCurrency(currencies)
        const result = makeArrayNull(currencyResponse)
        if (result === null) {
          localStorage.setItem('AllHotelCurr', null)
        } else {
          localStorage.setItem('AllHotelCurr', JSON.stringify(currencyResponse))
        }
        setLoading(false)
        sessionStorage.removeItem('FlightCheckOut')
      }
    } catch (error) {
      console.error('Error:', error)
      setLoading(false)
    }
  }

  // Currency Conversion

  const AllCurrency = async currencyArray => {
    const currtoken = CurrencyConverter()
    const requests = currencyArray.map(currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${currtoken}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      }

      return Axios.request(config)
        .then(response => response.data)
        .catch(error => {
          console.error(error)

          return [] // Return null for failed requests
        })
    })
    const results = await Promise.all(requests)
    var newdatcurr = results.filter(response => response !== null)
    return newdatcurr
  }
  function makeArrayNull (arr) {
    if (arr.every(element => Array.isArray(element) && element.length === 0)) {
      return null
    }
    return arr
  }

  // Filter Section

  const handleHotelFilter = inputValue => {
    setHotelid(inputValue.value)
    setHotelNameFilter(inputValue)
  }
  const handleChange = newRangeValue => {
    setRangeValue(newRangeValue)
  }
  const PriceFilter = () => {
    setShowPriceFilter(false)
    var data = { type: 'price', min: rangeValue[0], max: rangeValue[1] }
    setHotelid(data)
  }
  const handleCheckboxChange = event => {
    const { name, value } = event.target
    setStarRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '0') {
        updatedStarRating[name] = '0'
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }
  const StarFilter = () => {
    setShowRatingFilter(false)
    setHotelid(starRating)
  }
  const handleMealTypeChange = event => {
    const { name, value } = event.target

    setMealRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '') {
        updatedStarRating[name] = ''
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }
  const MealFilter = () => {
    setShowMealTYpeFilter(false)
    setHotelid(mealRating)
  }
  const handleFacilityChange = event => {
    const { name, value } = event.target
    const isSelected = selectedFacilities[name] === value

    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...selectedFacilities }
      delete updatedFacilities[name]
      setSelectedFacilities(updatedFacilities)
    } else {
      // If not selected, add it to the object
      setSelectedFacilities({ ...selectedFacilities, [name]: value })
    }
  }
  const FacilityFilter = () => {
    setShowFacilityFilter(false)
    setHotelid(selectedFacilities)
  }

  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%'
  }
  const handleorderbychange=(event)=>{
    var data = { type: 'order', value: event.target.value }
    setOrderFilter(event.target.value);
    setHotelid(data)
    
  }
  return (
    <>
      {loading && <Loading />}
      <Layout>
        <section id='common_banner' style={sectionStyle}>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-12'>
                <div class='common_bannner_text'>
                  <h2>Hotel Search Results</h2>
                  <ul>
                    <li>
                      <NavLink to='/'>Home</NavLink>
                    </li>
                    <li>
                      <span>
                        <i class='fas fa-circle'></i>
                      </span>
                      Hotels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='explore_area' class='section_padding'>
          <div class='container'>
            {/* <!-- Section Heading --> */}

            <div class='row'>
              <div class='col-lg-3'>
                <div class='left_side_search_area'>
                  <div class='left_side_search_boxed'>
                    <div class='left_side_search_heading'>
                      <h5>Search by name</h5>
                    </div>
                    <div class='name_search_form'>
                      <Select
                        value={hotelNameFilter}
                        onChange={handleHotelFilter}
                        options={searchHotelList.map(option => ({
                          value: option.hotel_id,
                          label: option.hotel_name
                        }))}
                      />
                    </div>
                  </div>
                  <div class='left_side_search_boxed'>
                    <div class='left_side_search_heading'>
                      <h5>Order By</h5>
                    </div>
                    <div class='name_search_form'>
                    <select onChange={handleorderbychange} value={orderFilter} class="form-select">
                      <option value="1">Low to High</option>
                      <option value="2">Highy to Low</option>
                      <option value="3">Order by A-Z</option>
                      <option value="4">Order by Z-A</option>
                      </select>
                    </div>
                  </div>
                  <div class='left_side_search_boxed'>
                    <div class='left_side_search_heading'>
                      <h5>Filter by price</h5>
                    </div>
                    <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={minValue}
                      tooltip={false}
                      max={maxValue}
                      step={1}
                    />
                    <div className='pt-2'>
                      <div className='fw-bold mb-2'>
                        Min:{' '}
                        <span id='kt_slider_basic_min'>{rangeValue[0]} </span>
                      </div>
                      <div className='fw-bold '>
                        Max:{' '}
                        <span id='kt_slider_basic_max'>{rangeValue[1]}</span>
                      </div>
                    </div>
                    <button
                      onClick={PriceFilter}
                      type='submit'
                      class='btn btn_theme btn_md w-100 mt-2 '
                    >
                      APPLY
                    </button>
                    {/* <button class="apply" type="button">Apply</button> */}
                  </div>
                  <div class='left_side_search_boxed'>
                    <div class='left_side_search_heading'>
                      <h5>Filter by Review</h5>
                    </div>
                    <div class='filter_review'>
                      <form class='review_star'>
                        <div class='form-check'>
                          {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                          <input
                            type='checkbox'
                            name='rating5'
                            onChange={handleCheckboxChange}
                            style={{ marginRight: '5px' }}
                            value='5'
                            class='form-check-input'
                          />
                          <label
                            class='form-check-label'
                            for='flexCheckDefault'
                          >
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                          </label>
                        </div>
                        <div class='form-check'>
                          {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1" /> */}
                          <input
                            type='checkbox'
                            name='rating4'
                            onChange={handleCheckboxChange}
                            style={{ marginRight: '5px' }}
                            value='4'
                            class='form-check-input'
                          />
                          <label
                            class='form-check-label'
                            for='flexCheckDefault1'
                          >
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_asse'></i>
                          </label>
                        </div>
                        <div class='form-check'>
                          {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2" /> */}
                          <input
                            type='checkbox'
                            name='rating3'
                            onChange={handleCheckboxChange}
                            style={{ marginRight: '5px' }}
                            value='3'
                            class='form-check-input'
                          />
                          <label
                            class='form-check-label'
                            for='flexCheckDefault2'
                          >
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_asse'></i>
                            <i class='fas fa-star color_asse'></i>
                          </label>
                        </div>
                        <div class='form-check'>
                          {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3" /> */}
                          <input
                            type='checkbox'
                            name='rating2'
                            onChange={handleCheckboxChange}
                            style={{ marginRight: '5px' }}
                            value='2'
                            class='form-check-input'
                          />
                          <label
                            class='form-check-label'
                            for='flexCheckDefault3'
                          >
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_asse'></i>
                            <i class='fas fa-star color_asse'></i>
                            <i class='fas fa-star color_asse'></i>
                          </label>
                        </div>
                        <div class='form-check'>
                          {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault5" /> */}
                          <input
                            type='checkbox'
                            name='rating1'
                            onChange={handleCheckboxChange}
                            style={{ marginRight: '5px' }}
                            value='1'
                            class='form-check-input'
                          />

                          <label
                            class='form-check-label'
                            for='flexCheckDefault5'
                          >
                            <i class='fas fa-star color_theme'></i>
                            <i class='fas fa-star color_asse'></i>
                            <i class='fas fa-star color_asse'></i>
                            <i class='fas fa-star color_asse'></i>
                            <i class='fas fa-star color_asse'></i>
                          </label>
                        </div>
                      </form>
                      <button
                        onClick={StarFilter}
                        type='submit'
                        class='btn btn_theme btn_md w-100 mt-2 '
                      >
                        APPLY
                      </button>
                    </div>
                  </div>

                  <div class='left_side_search_boxed'>
                    <div class='left_side_search_heading'>
                      <h5>Meal Type</h5>
                    </div>
                    <div class='tour_search_type'>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='ROOM ONLY'
                          name='meal1'
                          onChange={handleMealTypeChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultf1'
                        >
                          <span class='area_flex_one'>
                            <span>Room Only</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='BED AND BREAKFAST'
                          name='meal2'
                          onChange={handleMealTypeChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultf2'
                        >
                          <span class='area_flex_one'>
                            <span>Bed and BreakFast</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='HALF BOARD'
                          name='meal3'
                          onChange={handleMealTypeChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultaf3'
                        >
                          <span class='area_flex_one'>
                            <span>Half Board</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='Full BOARD'
                          name='meal3'
                          onChange={handleMealTypeChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultaf4'
                        >
                          <span class='area_flex_one'>
                            <span>Full Board</span>
                          </span>
                        </label>
                      </div>
                      <button
                        onClick={MealFilter}
                        type='submit'
                        class='btn btn_theme btn_md  w-100 mt-2 '
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                  <div class='left_side_search_boxed'>
                    <div class='left_side_search_heading'>
                      <h5>Facilities</h5>
                    </div>
                    <div class='tour_search_type'>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='Wi-fi'
                          name='facility1'
                          onChange={handleFacilityChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultt1'
                        >
                          <span class='area_flex_one'>
                            <span>Free Wifi</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='Internet access'
                          name='facility2'
                          onChange={handleFacilityChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultt2'
                        >
                          <span class='area_flex_one'>
                            <span>Internet Access</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='TV'
                          name='facility3'
                          onChange={handleFacilityChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultt3'
                        >
                          <span class='area_flex_one'>
                            <span>TV</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='Wake-up service'
                          name='facility4'
                          onChange={handleFacilityChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultt4'
                        >
                          <span class='area_flex_one'>
                            <span>Wake-Up Service</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='Smoking rooms'
                          name='facility5'
                          onChange={handleFacilityChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultt5'
                        >
                          <span class='area_flex_one'>
                            <span>Smoking Rooms</span>
                          </span>
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value='Wheelchair-accessible'
                          name='facility6'
                          onChange={handleFacilityChange}
                        />
                        <label
                          class='form-check-label'
                          for='flexCheckDefaultt5'
                        >
                          <span class='area_flex_one'>
                            <span>Wheel Chair Accessible</span>
                          </span>
                        </label>
                      </div>
                      <button
                        onClick={FacilityFilter}
                        type='submit'
                        class='btn btn_theme btn_md w-100 mt-2 '
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class='col-lg-9'>
                {searchHotelList.length === 0 ? (
                  <h5 className='text-center'>
                    Sorry! no hotels found in the given search.
                  </h5>
                ) : (
                  <div>
                    <HotelCard
                      hotelid={hotelid}
                      hotelDataNew={searchHotelList}
                      hotelsSearchData={hotelsSearchData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Hotels
