import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Hotelapitoken,
  ApiEndPoint,
} from "../../Components/GlobalData/GlobalData";
import {
  faCheck,
  faPlaneArrival,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import bag from "../../Images/Logo/tour-info.jpg";
import moment from "moment";
import header from "../../Images/header.png";
import footer from "../../Images/footer.png";
function ActivityInvoice() {
  const [leadPassenger, setLeadPassenger] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [AdditionalService, setAdditionalService] = useState([]);
  const [cartTotalData, setCartTotalData] = useState([]);
  const [InvoiceData, setInvoiceData] = useState([]);

  var endpoint = ApiEndPoint();

  useEffect(() => {
    invoicedata();
  }, []);
  const invoicedata = async () => {
    var tkn = Hotelapitoken();
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const lastPart = parts[parts.length - 1];
    var data = {
      token: tkn,
      booking_id: lastPart,
    };

    try {
      const response = await Axios.post(
        endpoint + "/api/invoice_data_react",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      // console.log(response);
      //     setCartData(response.data.cart_data);
      //     var totaldata=JSON.parse(response.data.cart_data[0].cart_total_data)
      setCartTotalData(response.data?.cart_data[0]);
      setTourData(response.data.inv_details);
      setInvoiceData(response.data.tour_data[0]);
      setLeadPassenger(JSON.parse(response.data.inv_details.passenger_detail));
      if (response.data?.cart_data[0].Additional_services_names !== "") {
        setAdditionalService(
          JSON.parse(response.data?.cart_data[0].Additional_services_names)
        );
      }

      //    setAccomdationDetail(JSON.parse(response.data.tour_data[0].accomodation_details))
      //    setFlightDetail(JSON.parse(response.data.tour_data[0].flights_details))
      //    setAccomdationDetail(JSON.parse(response.data.tour_data[0].accomodation_details))
      //    setReturnFlightDetail(JSON.parse(response.data.tour_data[0].return_flights_details))
      //    setLoading(false);
      // navigation(`/package_invoice/${id}`);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div class="container">
        <img style={{ width: "100%" }} src={header} alt="letterhead" />
      </div>
      <div className="container mt-5">
        <div className="row mb-2">
          <div className="text-end">
            <button
              onClick={() => window.print()}
              class="btn btn_theme w-25 select-styling search-btn2  m-1  detail-view-btn"
            >
              Print Voucher
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 ">
            <div className="tour_details_boxed mb-4">
              <div class="v-heading-icon clearfix ">
                <div class="row">
                  <div class="col-md-12 ">
                    <div className="invoice-heading">
                      <h4 className="text-center">Tour Information</h4>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                </div>
              </div>
              <div className="clearfix v-section-info">
                <ul className="list-items list-items-3 list-items-4  clearfix">
                  <li>
                    <span class="text-black ">Inovice No:</span>
                    <p class="f-20 text-black " id="makkah_booking_status">
                      {tourData.invoice_no}
                    </p>
                  </li>
                  <li>
                    <span class="text-black ">Tour Name:</span>
                    <p class="f-20 text-black " id="makkah_booking_status">
                      {cartTotalData?.tour_name}
                    </p>
                  </li>
                  {cartTotalData.adults !== "" && (
                    <li>
                      <span class="text-black ">Adults:</span>
                      <p class="f-20 text-black " id="makkah_booking_status">
                        {cartTotalData.adults}
                      </p>
                    </li>
                  )}
                  {cartTotalData.adults !== "" && (
                    <li>
                      <span class="text-black ">Adults Price:</span>
                      <p class="f-20 text-black " id="makkah_booking_status">
                        {cartTotalData.currency} {cartTotalData.adult_price}
                        <sub>pp</sub>
                      </p>
                    </li>
                  )}
                  {cartTotalData.childs !== "" && (
                    <li>
                      <span class="text-black ">Child Price:</span>
                      <p class="f-20 text-black " id="makkah_booking_status">
                        {cartTotalData.childs}
                      </p>
                    </li>
                  )}
                  {cartTotalData.childs !== "" && (
                    <li>
                      <span class="text-black ">Child Price:</span>
                      <p class="f-20 text-black " id="makkah_booking_status">
                        {cartTotalData.currency} {cartTotalData.child_price}
                        <sub>pp</sub>
                      </p>
                    </li>
                  )}
                  <li>
                    <span class="text-black ">Check In:</span>
                    <p class="f-20 text-black " id="makkah_booking_status">
                      {moment(cartTotalData.activity_select_date).format(
                        "DD-MM-YYYY"
                      )}
                    </p>
                  </li>
                  <li>
                    <span class="text-black ">Duration:</span>
                    <p class="f-20 text-black " id="makkah_booking_status">
                      {InvoiceData.duration} hours
                    </p>
                  </li>
                  <li>
                    <span class="text-black ">Destination:</span>
                    <p class="f-20 text-black " id="makkah_booking_status">
                      {InvoiceData.location}
                    </p>
                  </li>
                </ul>
              </div>
              {cartTotalData?.Additional_services_names !== "" && (
                <div class="v-heading-icon clearfix mt-3">
                  <div class="row">
                    <div class="col-md-8 ">
                      <div class="v-heading-icon-title float-left">
                        <h3>Additional Services</h3>
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
              )}
              {AdditionalService?.map((item, index) => (
                <div key={index} className="clearfix v-section-info">
                  <div className="text-center mt-3 p-1 bg-light">
                    <h5>{item.service}</h5>
                  </div>
                  <ul className="list-items list-items-3 list-items-4  clearfix">
                    <li>
                      <span class="text-black fw-bold">Service Type:</span>
                      <p
                        class="f-20 text-black fw-bold"
                        id="makkah_booking_status"
                      >
                        {item.service_type}
                      </p>
                    </li>
                    <li>
                      <p
                        class="f-20 text-black fw-bold"
                        id="makkah_booking_status"
                      ></p>
                    </li>
                    <li>
                      <span class="text-black fw-bold">Persons:</span>
                      <p
                        class="f-20 text-black fw-bold"
                        id="makkah_booking_status"
                      >
                        {item.person}
                      </p>
                    </li>
                    <li>
                      <span class="text-black fw-bold">Total Price:</span>
                      <p
                        class="f-20 text-black fw-bold"
                        id="makkah_booking_status"
                      >
                        {item.total_price}
                      </p>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4">
            <div className="tour_details_boxed">
              <div className="lead-passenger-detail">
                <div className=" invoice-heading">
                  <h5 className="text-center">Lead Passenger Details</h5>
                </div>
                <div className="row mt-2 ">
                  {leadPassenger.length > 0 ? (
                    <ul className="list-items list-items-3 list-items-4  clearfix">
                      <li>
                        <span class="text-black ">Full Name:</span>
                        {/* {leadPassenger.lead_title+leadPassenger.lead_first_name+" "+leadPassenger.lead_last_name} */}
                        <p class="f-20 text-black " id="makkah_booking_status">
                          {leadPassenger[0].lead_title +
                            " " +
                            leadPassenger[0].name +
                            " " +
                            leadPassenger[0].lname +
                            " "}
                        </p>
                      </li>
                      <li>
                        <span class="text-black ">Gender:</span>
                        {/* {leadPassenger.lead_phone} */}
                        <p class="f-20 text-black " id="makkah_booking_status">
                          {leadPassenger[0].gender}
                        </p>
                      </li>
                      <li>
                        <span class="text-black ">Phone Number:</span>
                        {/* {leadPassenger.lead_email} */}
                        <p class="f-20 text-black " id="makkah_booking_status">
                          {leadPassenger[0].phone}
                        </p>
                      </li>
                      <li>
                        <span class="text-black ">Email:</span>
                        {/* {leadPassenger.lead_email} */}
                        <p class="f-20 text-black " id="makkah_booking_status">
                          {leadPassenger[0].email}
                        </p>
                      </li>
                    </ul>
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
            </div>
            <div className="tour_details_boxed mt-5">
              <div className="lead-passenger-detail">
                <div className=" invoice-heading">
                  <h5 className="text-center">Payment Information</h5>
                </div>
                <div className="row mt-2 ">
                  <div className="c mt-2 mb-2 text-center ">
                    <h4 className=" ms-2">
                      Total: {cartTotalData.currency} {cartTotalData.price}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="tour_details_boxed mt-5">
              <div className="lead-passenger-detail">
                <div className=" invoice-heading">
                  <h5 className="text-center">Contact Information</h5>
                </div>
                <div className="text-center">
                  <h6>Feel free to contact us any time.</h6>
                </div>
                <div className="row mt-2 ">
                  <div className="col-md-4 col-4 col-sm-4 mt-2 invoice-lead-gest">
                    <h6 className=" ms-2">Phone:</h6>
                  </div>
                  <div className="col-md-8 col-sm-8 col-8 mt-2">
                    <p className=" ms-2">01254 675656</p>
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 mt-2 invoice-lead-gest">
                    <h6 className=" ms-2">Email:</h6>
                  </div>
                  <div className="col-md-8 col-sm-8 col-8 mt-2">
                    <p className=" ms-2"> hashimtravel@hotmail.co.uk</p>
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 mt-2 invoice-lead-gest">
                    <h6 className=" ms-2">Address:</h6>
                  </div>
                  <div className="col-md-8 col-sm-8 col-8 mb-2 mt-2">
                    <p className=" ms-2">
                      36 Audley Range, Blackburn, Lancashire, BB1 1TD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <img style={{ width: "100%" }} src={footer} alt="letterhead" />
      </div>
    </>
  );
}
export default ActivityInvoice;
