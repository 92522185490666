import React, { useState, useEffect } from "react";
import logo from "../Navbar/logo.png";
import Axios from "axios";
import ReactDOM from "react-dom";
import { json, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CurrencyConverter } from "../GlobalData/GlobalData";
import { Hotelapitoken, ApiEndPoint } from "../GlobalData/GlobalData";
import { CurrencyRates, AllCurrencyRates } from "../../Redux/Actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Drawer } from "rsuite";
import { useTranslation } from "react-i18next";
function Navbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  var token = Hotelapitoken();
  var endpoint = ApiEndPoint();
  const [countryList, setCountryList] = useState([]);
  const [dropdownCat, setDropdownCat] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("GBP");
  const [menuVisible, setMenuVisible] = useState(false);
  var flagsList = {
    GBP: "https://flagcdn.com/w320/gb.png",
    EUR: "https://flagcdn.com/w320/de.png",
    USD: "https://flagcdn.com/w320/us.png",
    SAR: "https://flagcdn.com/w320/sa.png",
    PKR: "https://flagcdn.com/w320/pk.png",
  };
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  // This function put query that helps to
  // change the language

  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem("selectedCurrency");
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency);
      CurrencyCalucaltion(storedCurrency);
    } else {
      dispatch(CurrencyRates(undefined));
    }
  }, []);
  async function fetchData() {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      "Content-Type": "application/json", // Specify content type if needed
    };
    try {
      const response = await Axios.get(
        endpoint + "/api/countries/fetch",
        headers
      );
      setCountryList(response.data.countries);
    } catch (error) {
      // Handle any errors
      console.error("Error:", error);
    }
  }
  const handleCurrencyDivClick = (currency) => {
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem("selectedCurrency", currency);

    // Perform other calculations or actions here
  };

  const CurrencyCalucaltion = (c) => {
    var token = CurrencyConverter();
    const config = {
      method: "get",
      url: "https://v6.exchangerate-api.com/v6/" + token + "/latest/" + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {},
    };

    Axios.request(config)
      .then((response) => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));
      })
      .catch((error) => {
        if (localStorage.getItem("selectedCurrency")) {
          localStorage.removeItem("selectedCurrency");
        }
        dispatch(CurrencyRates(undefined));
        // Handle errors here
        console.error(error);
      });
  };

  const AllCurrency = () => {
    var token = CurrencyConverter();
    const config = {
      method: "get",
      url: "https://v6.exchangerate-api.com/v6/" + token + "/latest/GBP", // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {},
    };

    Axios.request(config)
      .then((response) => {
        dispatch(AllCurrencyRates(response.data));
      })
      .catch((error) => {
        dispatch(AllCurrencyRates(undefined));
        // Handle errors here
        console.error(error);
      });
  };

  const GetCatagories = async () => {
    var data = {
      token: token,
    };
    try {
      const response = await Axios.post(
        endpoint + "/api/get_all_catigories_list_apis_new",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      sessionStorage.setItem(
        "Catogories",
        JSON.stringify(response.data.categories)
      );
      setDropdownCat(response.data.categories);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>

<Drawer placement="left" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Menu</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div class="mobile-nav">
            <ul class="mobile-listview">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/our-packages">Umrah Packages</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">Contact us</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About us</NavLink>
              </li>
              <li className="dropdown">
                <a
                  className="is_login dropdown-toggle"
                  id="currencyDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedCurrency} <i className="fa fa-angle-down"></i>
                </a>
                <ul
                  className="dropdown-menu text-left width-auto"
                  aria-labelledby="currencyDropdown"
                >
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick("EUR")}
                      className="is_login"
                    >
                      EUR
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick("GBP")}
                      className="is_login"
                    >
                      GBP
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick("USD")}
                      className="is_login"
                    >
                      USD
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick("SAR")}
                      className="is_login"
                    >
                      SAR
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick("PKR")}
                      className="is_login"
                    >
                      PKR
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Drawer.Body>
      </Drawer>
      <header class="main_header_arae">
    
        <div class="topbar-area">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6">
                <ul class="topbar-list">
                  <li>
                    <a href="#!">
                      <i class="fab fa-facebook"></i>
                    </a>
                    <a href="#!">
                      <i class="fab fa-twitter-square"></i>
                    </a>
                    <a href="#!">
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a href="#!">
                      <i class="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="tel://01254675656">
                      <span>01254 675656</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:hashimtravel@hotmail.co.uk">
                      <span>hashimtravel@hotmail.co.uk</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6">
                <ul class="topbar-others-options">
                  {/* <li>
                    <a href="login.html">Login</a>
                  </li>
                  <li>
                    <a href="register.html">Sign up</a>
                  </li> */}
                 
                  <li>
                    <div class="dropdown language-option">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        <img
                          src={flagsList[selectedCurrency]}
                          width="23"
                          className="me-2"
                        />
                        <span>{selectedCurrency}</span>
                        <span class="lang-name"></span>
                      </button>
                      <div class="dropdown-menu language-dropdown-menu">
                        <a
                          onClick={() => handleCurrencyDivClick("EUR")}
                          className="is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/de.png"
                            width="23"
                          />{" "}
                          EUR
                        </a>
                        <a
                          onClick={() => handleCurrencyDivClick("GBP")}
                          className="is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/gb.png"
                            width="23"
                          />{" "}
                          GBP
                        </a>
                        <a
                          onClick={() => handleCurrencyDivClick("USD")}
                          className="is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/us.png"
                            width="23"
                          />{" "}
                          USD
                        </a>
                        <a
                          onClick={() => handleCurrencyDivClick("SAR")}
                          className="is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/sa.png"
                            width="23"
                          />{" "}
                          SAR
                        </a>
                        <a
                          onClick={() => handleCurrencyDivClick("PKR")}
                          className="is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/pk.png"
                            width="23"
                          />{" "}
                          PKR
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

   
       
          <div className="navbar-area">
            {/* Responsive Navbar for Small Screens */}
            <div className="main-responsive-nav">
              <div className="container">
                <nav className="navbar navbar-expand-md navbar-light">
                  <NavLink className="navbar-brand" to="/">
                    <img
                      src={logo}
                      alt="logo"
                      width="100"
                      height="100"
                      className="d-inline-block align-text-top"
                    />
                  </NavLink>
                  {/* Toggle button for collapsing menu */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse mean-menu"
                    id="navbarResponsive"
                  >
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <NavLink to="/" className="nav-link">
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/our-packages" className="nav-link">
                          Umrah Packages
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/about-us" className="nav-link">
                          About
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/contact-us" className="nav-link">
                          Contact
                        </NavLink>
                      </li>
                      <li className="currency">
                  <div class="dropdown language-option">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      <img
                        src={flagsList[selectedCurrency]}
                        width="23"
                        className="me-2"
                      />
                      <span>{selectedCurrency}</span>
                      <span class="lang-name"></span>
                    </button>
                    <ul class="dropdown-menu language-dropdown-menu">
                     
                        <a
                          className=" text-dark is_login dropdown-item"
                          onClick={() => handleCurrencyDivClick("EUR")}
                          style={{ cursor: "pointer" }}
                          
                        >
                          <img
                            src="https://flagcdn.com/w320/de.png"
                            width="23"
                          />{" "}
                          EUR
                        </a>
                     
                     
                        <a
                          onClick={() => handleCurrencyDivClick("GBP")}
                          className="is_login text-dark dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/gb.png"
                            width="23"
                          />{" "}
                          GBP
                        </a>
                     
                     
                        {" "}
                        <a
                          onClick={() => handleCurrencyDivClick("USD")}
                          className="text-dark is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/us.png"
                            width="23"
                          />{" "}
                          USD
                        </a>
                      
                      
                        <a
                          onClick={() => handleCurrencyDivClick("SAR")}
                          className=" text-dark is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/sa.png"
                            width="23"
                          />{" "}
                          SAR
                        </a>
                     
                      
                        {" "}
                        <a
                          onClick={() => handleCurrencyDivClick("PKR")}
                          className=" text-dark is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/pk.png"
                            width="23"
                          />{" "}
                          PKR
                        </a>
                    
                    </ul>
              
                  </div>
                </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>

      
            <div className="main-navbar">
              <div className="container">
                <nav className="navbar navbar-expand-md navbar-light">
                  <NavLink className="navbar-brand" to="/">
                    <img
                      src={logo}
                      alt="logo"
                      width="100"
                      height="100"
                      className="d-inline-block align-text-top"
                    />
                  </NavLink>
                  <div
                    className="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <NavLink to="/" className="nav-link ">
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/our-packages" className="nav-link ">
                          Umrah Packages
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/about-us" className="nav-link">
                          About
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/contact-us" className="nav-link">
                          Contact
                        </NavLink>
                      </li>

                      <li className="currency">
                  <div class="dropdown language-option">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      <img
                        src={flagsList[selectedCurrency]}
                        width="23"
                        className="me-2"
                      />
                      <span>{selectedCurrency}</span>
                      <span class="lang-name"></span>
                    </button>
                    <ul class="dropdown-menu language-dropdown-menu">
                     
                        <a
                          className=" text-dark is_login dropdown-item"
                          onClick={() => handleCurrencyDivClick("EUR")}
                          style={{ cursor: "pointer" }}
                          
                        >
                          <img
                            src="https://flagcdn.com/w320/de.png"
                            width="23"
                          />{" "}
                          EUR
                        </a>
                     
                     
                        <a
                          onClick={() => handleCurrencyDivClick("GBP")}
                          className="is_login text-dark dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/gb.png"
                            width="23"
                          />{" "}
                          GBP
                        </a>
                     
                     
                        {" "}
                        <a
                          onClick={() => handleCurrencyDivClick("USD")}
                          className="text-dark is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/us.png"
                            width="23"
                          />{" "}
                          USD
                        </a>
                      
                      
                        <a
                          onClick={() => handleCurrencyDivClick("SAR")}
                          className=" text-dark is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/sa.png"
                            width="23"
                          />{" "}
                          SAR
                        </a>
                     
                      
                        {" "}
                        <a
                          onClick={() => handleCurrencyDivClick("PKR")}
                          className=" text-dark is_login dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="https://flagcdn.com/w320/pk.png"
                            width="23"
                          />{" "}
                          PKR
                        </a>
                    
                    </ul>
              
                  </div>
                </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
       
      </header>

    </>
  );
}

export default Navbar;
